









































































import {Component, Vue} from 'vue-property-decorator';
import CusTable from "@/components/table";
import MixinEdu from "@/views/education/Mixins/mixin-edu";
@Component({
  name: 'Subject',
  components:{CusTable},
  mixins: [ MixinEdu ]
})
export default class Subject extends Vue{
  constructor(prop) {
    super(prop);
  };
  private tableHead:Array<{[key:string]: any}> = [
    {
      property: 'name',
      label: '学期名称',
      width: 'auto',
      align: 'left'
    },
    {
      property: 'modifierName',
      label: '修改人',
      width: 'auto',
      align: 'left'
    },
    {
      property: 'modifyTime',
      label: '修改时间',
      width: 'auto',
      align: 'left'
    },
    {
      property: 'status',
      label: '状态',
      width: 'auto',
      align: 'left',
      render: (row:any, column:any, cellValue:any, index:number) => {
        return row.status == 0 ? '启用' : '未启用'
      }
    }
  ];

  private AddDialogVisible = false;
  private inputVal = '';
  private rowId = '';
  $refs!: {table: CusTable}

  /**
   * 批量停用/启用
   */
  private batchChangeStatusHandle(status) {
    this.batchChangeStatus(status, this.$refs.table);
  };

  /**
   * 停用 && 启用
   */
  private handleChangeStatus(idx, row: never | any) {
    this.selectedArr = [row];
    this.batchChangeStatus((row.status == 1 ? 0 : 1), this.$refs.table)
  }

  private handleDelete(idx, row: never | any) {
    this.selectedArr = [row];
    this.batchDeleteHandle(this.$refs.table);
  }

  private selectionChange(ev) {
    this.selectedArr = ev;
  };

  private async addGrade() {
    let data = {
      id: this.rowId,
      typeCode: (this.$route as any).meta.type,
      name: this.inputVal,
    }
    const res: any = await this.axios.post('/system/dictionary/saveOrUpdate', data);
    if (res.result) {
      this.cusTableKey = Date.now();
      this.AddDialogVisible = !this.AddDialogVisible;
    }
  };

  private dialogClose() {
  };

  private handleEdit(idx, row) {
    this.AddDialogVisible = true;
    this.rowId = row.id;
    this.inputVal = row.name;
  }
}
